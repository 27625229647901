@import url(https://fonts.googleapis.com/css?family=Lustria&display=swap);
@import url(https://fonts.googleapis.com/css?family=Nunito:300,400&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root {
  --primaryColor: #af9a7d;
  --mainWhite: #fff;
  --offWhite: #f7f7f7;
  --mainBlack: #222;
  --mainGrey: #ececec;
  --darkGrey: #cfcfcf;
  --mainTransition: all 0.3s linear;
  --mainSpacing: 3px;
  --lightShadow: 2px 5px 3px 0px rgba(0, 0, 0, 0.5);
  --darkShadow: 4px 10px 5px 0px rgba(0, 0, 0, 0.5);
}
/* globals */
body {
  padding-top: 66px;
  color: #222;
  color: var(--mainBlack);
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  line-height: 1.4;
}
h1 {
  font-size: 3em;
  line-height: 1;
  margin-bottom: 0.5em;
}
h2 {
  font-size: 2em;
  margin-bottom: 0.75em;
}
h3 {
  font-size: 1.5em;
  line-height: 1;
  margin-bottom: 1em;
}
h4 {
  font-size: 1.2em;
  line-height: 1.25;
  margin-bottom: 1.25em;
}
h5 {
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 1.5em;
}
h6 {
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 1.5em;
}

.btn-primary {
  display: inline-block;
  text-decoration: none;
  letter-spacing: 3px;
  letter-spacing: var(--mainSpacing);
  color: white;
  background: transparent;
  padding: 0.4rem 1rem;
  border: 2px solid white;
  transition: all 0.3s linear;
  transition: var(--mainTransition);
  text-transform: uppercase;
  cursor: pointer;
}
.btn-primary:hover {
  border-radius: 5px;
  background: #deb668;
}

.loading {
  text-transform: capitalize;
  text-align: center;
  margin-top: 3rem;
}
.error {
  text-align: center;
  text-transform: uppercase;
  margin: 2rem 0;
}
.empty-search {
  text-align: center;
  text-transform: capitalize;
  margin: 2rem 0;
  padding: 1rem;
  letter-spacing: 3px;
  letter-spacing: var(--mainSpacing);
}
/* end of globals */
/* Navbar */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0rem 2rem;
  background: #ffffff;
  z-index: 1;
}
.nav-header {
  display: flex;
  justify-content: space-between;
}
.nav-btn {
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
}

.nav-header a{
  text-decoration: none;
}

.nav-logo-vip::before {
  content: "\2655";
  font-size: 2.1rem;
  margin-right: 5px;
}

.nav-logo-vip {
  margin: 8px 0 0 30px;
  font-family: 'Nunito', sans-serif;
  font-size: 2rem;
  text-transform: uppercase;
  color: transparent;
  font-weight: 600;
  letter-spacing: 1.5px;
  background-clip: text;
  -webkit-background-clip: text;
  background-image: linear-gradient(to right, #fc8973, #d10c64);
  text-shadow: 1px 1px 2px rgba(247, 97, 38, 0.7);
  transition: all .3s;
  position: relative;
}

.nav-logo-vip:hover {
  text-shadow: none;
}

.nav-icon {
  font-size: 1.5rem;
  color: #af9a7d;
  color: var(--primaryColor);
}
.nav-links {
  margin-top: 0.5rem;
  height: 0;
  overflow: hidden;
  transition: all 0.3s linear;
  transition: var(--mainTransition);
  list-style-type: none;
  
}
.nav-links a {
  font-family: 'Nunito', sans-serif;
  display: block;
  text-decoration: none;
  padding: 1rem 0;
  color: rgb(24, 1, 1);
  transition: all 0.3s linear;
  transition: var(--mainTransition);
  text-align: center;
  font-size: 1.1rem;
  font-weight: 600;
  letter-spacing: 1px;
  position: relative;
}

.nav-links a::after, .nav-logo-vip::after {
  position: absolute;
    content: "";
    display: block;
    margin: 0 auto;
    width: 0px;
    height: 2px;
    background-color: #f35a13;
    transition: opacity .3s ease,width .3s ease;
    right: 0;
    left: 0;
    pointer-events: none;
}

.nav-logo-vip::after {
    margin: -5px auto;
    height: 3px;
    background-color: #f35a13;
}

.nav-links a:hover::after, .nav-logo-vip:hover::after{
  width: 50px;
}

.nav-links a:hover{
  color: #f35a13;
}

.show-nav {
  height: 100px;
}
@media screen and (min-width: 768px) {
  .nav-btn {
    display: none;
  }
  .nav-center {
    max-width: 1170px;
    margin: 0 auto;
    display: flex;
  }
  .nav-links {
    height: auto;
    display: flex;
    margin-left: 34rem;
  }
  .nav-links a {
    margin: 0 1rem;
    padding: 0.5rem 0;
  }
}
/* end of navbar */
/* Hero */
.defaultHero,
.roomsHero {
  min-height: calc(100vh - 56px);
  background: url(/static/media/bg.8c93e3ca.jpg) center/cover no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -10px;
}
.roomsHero {
  background-image: url(/static/media/room-2.052c7681.jpeg);
  min-height: 60vh;
}
/* End of Hero */
/* Banner */
.banner {
  display: inline-block;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  color: var(--mainWhite);
  padding: 2rem 1rem;
  text-align: center;
  text-transform: capitalize;
  letter-spacing: 3px;
  letter-spacing: var(--mainSpacing);
}
.banner h1 {
  font-size: 2.5rem;
}
.banner div {
  width: 10rem;
  height: 5px;
  background: #af9a7d;
  background: var(--primaryColor);
  margin: 1.7rem auto;
}
.banner p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}
@media screen and (min-width: 576px) {
  .banner {
    padding: 2rem 3rem;
  }
  .banner h1 {
    font-size: 3rem;
  }
}
@media screen and (min-width: 992px) {
  .banner {
    padding: 2rem 6rem;
  }
  .banner h1 {
    font-size: 4rem;
  }
}
/* End of Banner */
/* Title */
.section-title {
  text-align: center;
  margin-bottom: 4rem;
}
.section-title h4 {
  font-size: 1.8rem;
  letter-spacing: 3px;
  letter-spacing: var(--mainSpacing);
  text-transform: capitalize;
  margin-bottom: 1rem;
  text-transform: uppercase;

}
.section-title div {
  width: 5rem;
  height: 5px;
  margin: 0 auto;
  background: #deb668;
}
/* end of Title */

/* services */
.services {
  padding: 5rem 0;
}
.services {
  font-family: 'Nunito', sans-serif;
  background-color: #162541;
  text-align: center;
  color: white;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.1rem;
}
.services-center {
  width: 90vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(255px, 1fr));
  grid-row-gap: 2rem;
  grid-column-gap: 50px;
}
.service span {
  display: inline-block;
  color: #ff4157;
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
}
.services h6 {
  text-transform: uppercase;
  letter-spacing: 3px;
  letter-spacing: var(--mainSpacing);
}
.services p {
  line-height: 1.3;
  width: 80%;
  margin: 0 auto;
  font-size: 1.1rem;
}
@media screen and (min-width: 992px) {
  .services-center {
    width: 95vw;
    max-width: 1170px;
  }
}

@media screen and (min-width: 1200px) {
  .services p {
    width: 100%;
  }
}
/*end of services */
/* featured rooms */

.featured-rooms {
  padding: 5rem 0;
}
.featured-rooms-center {
  width: 80vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
  grid-row-gap: 2rem;
  grid-column-gap: 40px;
}
@media screen and (min-width: 776px) {
  .featured-rooms-center {
    width: 90vw;
    grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
  }
}
@media screen and (min-width: 992px) {
  .featured-rooms-center {
    width: 95vw;
    max-width: 1170px;
  }
}
/* end pf featured rooms */
/* room */
.room {
  box-shadow: 2px 5px 3px 0px rgba(0, 0, 0, 0.5);
  box-shadow: var(--lightShadow);
  transition: all 0.3s linear;
  transition: var(--mainTransition);
}
.room:hover {
  box-shadow: 4px 10px 5px 0px rgba(0, 0, 0, 0.5);
  box-shadow: var(--darkShadow);
}

.img-container {
  position: relative;
}
.img-container img {
  width: 100%;
  display: block;
  transition: all 0.3s linear;
  transition: var(--mainTransition);
}
.price-top {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  color: var(--mainWhite);
  padding: 0.3rem 0.6rem 0.5rem;
  border-bottom-right-radius: 1rem;
  font-size: 0.5rem;
  text-align: center;
  transition: all 0.3s linear;
  transition: var(--mainTransition);
}
.price-top h6 {
  margin-bottom: 0;
  font-size: 0.9rem;
  font-weight: 300;
  letter-spacing: 3px;
  letter-spacing: var(--mainSpacing);
}
.room-link {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: scale(0);
}
.img-container:hover {
  background: rgba(0, 0, 0, 0.4);
}
.img-container:hover img {
  -webkit-filter: blur(3px);
          filter: blur(3px);
}
.img-container:hover .price-top {
  opacity: 0;
}
.img-container:hover .room-link {
  transform: translate(-50%, -50%) scale(1);
}
.room-info {
  font-family: 'Nunito', sans-serif;
  background: white;
  text-transform: capitalize;
  padding: 0.8rem 0;
  text-align: center;
  font-weight: 700;
  letter-spacing: 2px;
  color: #606060;
  text-transform: uppercase;
}
/* end of room  */
/* single room*/

.single-room {
  padding: 5rem 0 0 0;
}
.single-room-images {
  width: 80vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-row-gap: 2rem;
  grid-column-gap: 50px;
}
.single-room-images img {
  width: 100%;
  display: block;
}
.single-room-info {
  width: 80vw;
  display: grid;
  grid-template-columns: 1fr;
  margin: 2rem auto;
}
.desc,
.info {
  margin: 1rem 0;
}
.desc h3 {
  text-transform: capitalize;
  letter-spacing: 3px;
  letter-spacing: var(--mainSpacing);
}
.desc p {
  line-height: 1.5;
}
.info h3,
.info h6 {
  text-transform: capitalize;
  letter-spacing: 3px;
  letter-spacing: var(--mainSpacing);
}

.info h6 {
  font-weight: 300;
}
.room-extras {
  width: 80vw;
  margin: 0 auto 3rem auto;
}
.room-extras h6 {
  text-transform: capitalize;
  letter-spacing: 3px;
  letter-spacing: var(--mainSpacing);
}
.extras {
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
  grid-column-gap: 2rem;
  grid-row-gap: 1rem;
}
@media screen and (min-width: 992px) {
  .single-room-images,
  .single-room-info,
  .room-extras {
    width: 95vw;
    max-width: 1170px;
  }
  .single-room-info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2rem;
  }
  .info {
    padding-left: 3rem;
  }
}
/* end of single room*/
/* roomlist */
.roomslist {
  padding: 5rem 0;
}
.roomslist-center {
  width: 80vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  grid-row-gap: 2rem;
  grid-column-gap: 30px;
}

@media screen and (min-width: 776px) {
  .roomslist-center {
    width: 90vw;
  }
}
@media screen and (min-width: 992px) {
  .roomslist-center {
    width: 95vw;
    max-width: 1170px;
  }
}
/* end of roomlist */
/*  rooms fitler*/
.filter-container {
  padding: 5rem 0;
}
.filter-form {
  width: 60vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(202px, 1fr));
  grid-row-gap: 2rem;
  grid-column-gap: 40px;
}
.form-group {
  text-transform: capitalize;
}
.form-group label {
  display: block;
  letter-spacing: 3px;
  letter-spacing: var(--mainSpacing);
  margin-bottom: 0.5rem;
}
.form-control {
  width: 100%;
  background: transparent;
  font-size: 1rem;
}
.size-inputs {
  display: flex;
}
.size-input {
  width: 40%;
  padding: 0.2rem;
  border: 1px solid #222;
  border: 1px solid var(--mainBlack);
  border-radius: 0.3rem;
  margin-right: 0.3rem;
}
.single-extra label {
  display: inline-block;
  font-size: 0.8rem;
  margin-left: 0.5rem;
}
@media screen and (min-width: 776px) {
  .filter-form {
    width: 70vw;
  }
}
@media screen and (min-width: 992px) {
  .filter-form {
    width: 95vw;
    max-width: 1170px;
  }
}
/* end of rooms fitler*/

